import React, { useState } from 'react';
import styled from 'styled-components';
import Navbar from './NavigationLoggedIn';
import TabbedCart from './TabbedCart';
import axios from 'axios';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  flex: 1;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-left: 50px;
  filter: ${({ isBlurred }) => (isBlurred ? 'blur(5px)' : 'none')};
  transition: filter 0.3s ease;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchBox = styled.input`
  width: 400px;
  height: 30px;
  padding: 5px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: #000000;
  margin-right: 10px;
`;

const Button = styled.button`
  width: 100px;
  height: 40px;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #ffffff;
  margin-right: 10px;
  cursor: pointer;
`;

const DataFrameContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const DataFrame = styled.table`
  width: ${({ $isSelected }) => ($isSelected ? '20%' : '80%')};
  border-collapse: collapse;
  color: #ffffff;
  border: 1px solid #414649;
  transition: width 0.3s ease-in-out;
  height: 65vh;
  max-height: 65vh;
  justify-self: center;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #414649;
  background-color: #2c6b5f;
`;

const TableData = styled.td`
  padding: 12px;
  border-bottom: 1px solid #414649;
  cursor: pointer;
`;

const ItemDetailsContainer = styled.div`
  flex: 1;
  background-color: #000000;
  border: 1px solid #ffffff;
  padding: 40px;
  margin-left: 20px;
  color: #ffffff;
  height: 65vh;
  max-height: 65vh
  display: ${({ $isSelected }) => ($isSelected ? 'block' : 'none')};
`;

const BackButton = styled.button`
  background-color: transparent;
  color: #ffffff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 10px;
`;

const PaginationButton = styled.button`
  background-color: ${({ active }) => (active ? '#2c6b5f' : '#000000')};
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 5px 10px;
  margin: 10px;
  cursor: pointer;
`;

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isBlurred, setIsBlurred] = useState(false);
  const itemsPerPage = 10;

  const handleSearch = async () => {
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');

    try {
      const response = await axios.get('http://127.0.0.1:5000/search', {
        params: { q: searchTerm },
        auth: { username, password },
      });
      setResults(response.data); // Update results based on search
    } catch (error) {
      console.error('Error performing search:', error);
    }
  };

  const handleBack = () => {
    setSelectedItem(null);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleExpand = (expanded) => {
    setIsBlurred(expanded);
  };

  const handleClick = (item) => {
    setSelectedItem(item);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = results.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(results.length / itemsPerPage);
    const pageNumbers = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (endPage - startPage < 4) {
      if (currentPage <= 3) {
        endPage = Math.min(5, totalPages);
      } else if (currentPage > totalPages - 3) {
        startPage = Math.max(1, totalPages - 4);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={i}
          onClick={() => handlePageChange(i)}
          active={i === currentPage}
        >
          {i}
        </PaginationButton>
      );
    }

    return pageNumbers;
  };

  return (
    <PageContainer>
      <Navbar />
      <ContentContainer isBlurred={isBlurred}>
        <TopContainer>
          <SearchContainer>
            <SearchBox
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
            <Button onClick={handleSearch}>Search</Button>
          </SearchContainer>
          <div>
            <PaginationButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </PaginationButton>
            {renderPageNumbers()}
            <PaginationButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={indexOfLastItem >= results.length}
            >
              &gt;
            </PaginationButton>
          </div>
        </TopContainer>
        <DataFrameContainer>
          <DataFrame $isSelected={!!selectedItem}>
            <thead>
              <tr>
                <TableHeader>Name</TableHeader>
                <TableHeader>Item ID</TableHeader>
                {!selectedItem && (
                  <>
                    <TableHeader>Price</TableHeader>
                    <TableHeader>Item Size</TableHeader>
                    <TableHeader>NDC</TableHeader>
                    <TableHeader>UPC</TableHeader>
                    <TableHeader>Past Orders</TableHeader>
                    <TableHeader>Last Order Date</TableHeader>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index} onClick={() => handleClick(item)}>
                  <TableData>{item[0]}</TableData>
                  <TableData>{item[1]}</TableData>
                  {!selectedItem && (
                    <>
                      <TableData>{item[2]}</TableData>
                      <TableData>{item[3]}</TableData>
                      <TableData>{item[4]}</TableData>
                      <TableData>{item[5]}</TableData>
                      <TableData>{item[6]}</TableData>
                      <TableData>{item[7]}</TableData>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </DataFrame>
          {selectedItem && (
            <ItemDetailsContainer $isSelected={!!selectedItem}>
              <BackButton onClick={handleBack}>&larr;</BackButton>
              <h2>{selectedItem[0]}</h2>
              <p>Item ID: {selectedItem[1]}</p>
              <p>Price: {selectedItem[2]}</p>
              <p>Item Size: {selectedItem[3]}</p>
              <p>NDC: {selectedItem[4]}</p>
              <p>UPC: {selectedItem[5]}</p>
              <p>Stock: {selectedItem[6]}</p>
              <p>Last Order Date: {selectedItem[7]}</p>
            </ItemDetailsContainer>
          )}
        </DataFrameContainer>
      </ContentContainer>
      <TabbedCart onExpand={handleExpand} />
    </PageContainer>
  );
};

export default Search;
