import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import AwsSVG from '../assets/aws-startups.svg';

import ChatIcon from '../assets/chat.svg';
import AnalyticsIcon from '../assets/analytics.svg';
import CartIcon from '../assets/basket.svg';
import OrderIcon from '../assets/charticon.svg';
import ChieldIcon from '../assets/Chield.svg';
import TimeIcon from '../assets/Time.svg';
import Doctor from '../assets/doctor.svg';
import Footer from './Footer';
import LayeredWaves from '../assets/layered-waves-haikei.svg';

// Responsive breakpoints
const breakpoints = {
  mobile: '768px',
  tablet: '1024px',
  laptop: '1366px', 
  desktop: '1600px',
};

const media = {
  mobile: `(max-width: ${breakpoints.mobile})`,
  tablet: `(max-width: ${breakpoints.tablet})`,
  laptop: `(max-width: ${breakpoints.laptop})`,
  desktop: `(max-width: ${breakpoints.desktop})`,
};

// Container Styled-Component
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  background-color: #fff;
  color: #000;
  overflow-x: hidden;  
  position: relative;

  /* Default background for desktop and larger */
  background-image: url(${LayeredWaves});
  background-size: 100vw;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 100vh; /* Ensures full viewport height on desktop */

  /* Adjustments for desktop */
  @media (min-width: ${parseInt(breakpoints.tablet) + 1}px) {
    /* No changes needed as desktop already has background */
  }

  /* Remove background on tablet and mobile */
  @media ${media.tablet} {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background-image: none; /* Remove background on tablet */
    min-height: auto; /* Reset height to auto */
  }

  @media ${media.mobile} {
    flex-direction: column;
    align-items: center;
    padding: 20px 15px;
    background-image: none; /* Remove background on mobile */
    min-height: auto; /* Reset height to auto */
  }
`;

// TextContainer Styled-Component
const TextContainer = styled.div`
  width: 50%;
  text-align: left;
  margin-top: 40px;
  padding-left: 5rem;
  padding-right: 10px;
  min-height: 400px;
  overflow-x: hidden; 

  @media ${media.desktop} {
    width: 55%;
  }

  @media ${media.tablet} {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

  @media ${media.mobile} {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
`;

// Title Styled-Component
const Title = styled.h1`
  font-family: Geist, sans-serif;
  font-size: 4rem;
  margin: 0;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: normal;
  color: #000;

  &:not(:first-child) {
    margin-top: 5px;
  }

  @media ${media.desktop} {
    font-size: 3.5rem;
  }

  @media ${media.laptop} {
    font-size: 2.8rem;
  }

  @media ${media.tablet} {
    text-align: center;
  }

  @media ${media.mobile} {
    font-size: 2.2rem;
  }
`;

// Subtitle Styled-Component
const Subtitle = styled.h2`
  font-family: Geist, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  margin: 20px 0 30px;
  max-width: 80%;
  letter-spacing: 0.5px;
  color: #555;

  @media ${media.tablet} {
    max-width: 100%;
    margin: 20px auto 30px;
    font-size: 1.5rem;
  }

  @media ${media.mobile} {
    font-size: 1rem;
  }
`;

// ChartContainer Styled-Component
const ChartContainer = styled.div`
  width: 50%;
  min-height: 450px;
  margin-top: 0px;
  align-items: center;

  @media ${media.desktop} {
    width: 45%;
  }

  @media ${media.laptop} {
    width: 60%;
  }

  @media ${media.tablet} {
    width: 80%;
    margin-top: 30px;
  }

  @media ${media.mobile} {
    width: 100%;
    margin-top: 20px;
  }
`;

// ButtonContainer Styled-Component
const ButtonContainer = styled.div`
  @media ${media.tablet} {
    text-align: center;
  }
`;

// StartButton Styled-Component
const StartButton = styled.button`
  font-family: Geist, sans-serif;
  padding: 10px 30px;
  background-image: linear-gradient(30deg, #0400ff, gray);
  border: none;
  color: #fff;
  font-size: 1.1rem;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 30px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #00a0a0;
  }

  @media ${media.desktop} {
    font-size: 1rem;
    padding: 12px 25px;
  }

  @media ${media.mobile} {
    font-size: 0.9rem;
    width: 100%;
    padding: 12px 0;
  }
`;

// SVGImage Styled-Component
const SVGImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

// FooterContainer Styled-Component
const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  padding: 25px;

  @media ${media.mobile} {
    padding: 20px 10px;
  }
`;

// PoweredByText Styled-Component
const PoweredByText = styled.div`
  font-size: 1rem;
  color: #333;
  margin-bottom: 20px;
  font-family: Geist, sans-serif;
  font-weight: 500;
  opacity: 0.7;

  @media ${media.mobile} {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
`;

// PoweredByImages Styled-Component
const PoweredByImages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; 
`;

// PoweredByImage Styled-Component
const PoweredByImage = styled.img`
  height: 60px;
  margin: 0 20px 20px;

  @media ${media.mobile} {
    height: 50px;
    margin: 0 10px 15px;
  }
`;

// ServicesSection Styled-Component
const ServicesSection = styled.section`
  background: #fff;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${media.mobile} {
    padding: 60px 15px;
  }
`;

// ServicesTitle Styled-Component
const ServicesTitle = styled.h1`
  font-family: Geist, sans-serif;
  font-size: 2rem;
  text-align: center;
  margin: 0 0 50px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: normal;
  color: #000;
  opacity: 0.8;

  @media ${media.mobile} {
    font-size: 1.8rem;
    margin-bottom: 40px;
  }
`;

// ServicesGrid Styled-Component
const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 900px;
  width: 100%;

  @media ${media.mobile} {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

// ServiceItem Styled-Component
const ServiceItem = styled.div`
  background: #f5f5f5;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  border: 1px solid #ddd;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: #e6f8f8;
    border-color: #2c6b5f;
  }

  @media ${media.mobile} {
    padding: 20px;
  }
`;

// ServiceIcon Styled-Component
const ServiceIcon = styled.img`
  width: 50px;
  height: 50px;
  margin: 0 auto 15px;

  @media ${media.mobile} {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
`;

// ServiceTitle Styled-Component
const ServiceTitle = styled.h3`
  font-family: Geist, sans-serif;
  font-weight: 600;
  color: #000;
  font-size: 1.5rem;
  margin-bottom: 10px;

  @media ${media.tablet} {
    font-size: 1.4rem;
  }

  @media ${media.mobile} {
    font-size: 1.3rem;
  }
`;

// ServiceDesc Styled-Component
const ServiceDesc = styled.p`
  color: #555;
  font-size: 1rem;
  font-weight: 500;

  @media ${media.mobile} {
    font-size: 0.9rem;
  }
`;

// Services Data Array
const servicesData = [
  {
    icon: ChatIcon,
    title: 'AI Chat',
    description: 'Ask any question about your ordering and get instant answers.',
  },
  {
    icon: AnalyticsIcon,
    title: 'Item Analytics',
    description: 'Get advanced analytics and compare competitor pricing instantly.',
  },
  {
    icon: CartIcon,
    title: 'Cart Tracking',
    description: 'Manage multiple carts for limitless vendors all on one dashboard.',
  },
  {
    icon: OrderIcon,
    title: 'Predictive Ordering',
    description: 'Get optimized carts based on pricing, seasonal trends, and more.',
  },
  {
    icon: ChieldIcon,
    title: 'Theft Detection',
    description: 'Identify theft and fraud in your pharmacy with ease.',
  },
  {
    icon: TimeIcon,
    title: 'Reminders',
    description: 'Set and receive reminders for important tasks and events.',
  },
];

// Main App Component
const App = () => {
  const chartFrames = [Doctor];
  const [currentChartIndex, setCurrentChartIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentChartIndex((prevIndex) => (prevIndex + 1) % chartFrames.length);
    }, 400);

    return () => {
      clearInterval(interval);
    };
  }, [chartFrames.length]);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/signup'); 
  };

  return (
    <>
      <Container>
        <TextContainer>
          <Title>The Future of</Title>
          <Title>Pharmacy</Title>
          <Title>Management</Title>
          <Subtitle>
            Spend more time filling prescriptions, we'll handle the rest
          </Subtitle>
          <ButtonContainer>
            <StartButton onClick={handleButtonClick}>Schedule a Demo</StartButton>
          </ButtonContainer>
        </TextContainer>
        <ChartContainer>
          <SVGImage src={chartFrames[currentChartIndex]} alt="Chart" />
        </ChartContainer>
      </Container>
      <FooterContainer>
        <PoweredByText>Powered by</PoweredByText>
        <PoweredByImages>
          <PoweredByImage src={AwsSVG} alt="AWS" />
          {/* Add more PoweredByImage components if needed */}
        </PoweredByImages>
      </FooterContainer>

      <ServicesSection>
        <ServicesTitle>Our Services</ServicesTitle>
        <ServicesGrid>
          {servicesData.map((service, index) => (
            <ServiceItem key={index}>
              <ServiceIcon src={service.icon} alt={service.title} />
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDesc>{service.description}</ServiceDesc>
            </ServiceItem>
          ))}
        </ServicesGrid>
      </ServicesSection>
      <Footer />
    </>
  );
};

export default App;
