import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const breakpoints = {
  mobile: '768px',
};

const media = {
  mobile: `(max-width: ${breakpoints.mobile})`,
};

const Nav = styled.nav`
  background: #fff;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: 1px solid #eee;
  position: relative;

  @media ${media.mobile} {
    flex-direction: column;
    padding: 10px 20px;
    height: auto;
  }
`;

const Logo = styled(LogoSVG)`
  width: auto;
  height: 20px;
  background-color: #fff;
  transition: height 0.3s ease;

  @media ${media.mobile} {
    height: 30px;
    margin-bottom: 10px;
  }
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;




const AuthLinks = styled.div`
  display: flex;
  align-items: center;

  @media ${media.mobile} {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
`;

const Button = styled.button`
  border: none;
 color: #fff;
 background-image: linear-gradient(30deg, #0400ff, gray);
 border-radius: 20px;
 background-size: 100% auto;
 font-family: inherit;
 font-size: 17px;
 font-weight: 600;
 padding: 0.5em 1.2em;
 margin-right: .rem;
}

button:hover {
 background-position: right center;
 background-size: 200% auto;
 -webkit-animation: pulse 2s infinite;
 animation: pulse512 1.5s infinite;
}

@keyframes pulse512 {
 0% {
  box-shadow: 0 0 0 0 #05bada66;
 }

 70% {
  box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
 }

 100% {
  box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
 }
`;
const Button2 = styled.button`
  border: none;
 color: #fff;
 background-image: linear-gradient(30deg, black, gray);
 border-radius: 20px;
 background-size: 100% auto;
 font-family: inherit;
 font-size: 17px;
 font-weight: 600;
 padding: 0.5em 1.5em;
 margin-left: 1.5rem;
}

button:hover {
 background-position: right center;
 background-size: 200% auto;
 -webkit-animation: pulse 2s infinite;
 animation: pulse512 1.5s infinite;
}

@keyframes pulse512 {
 0% {
  box-shadow: 0 0 0 0 #05bada66;
 }

 70% {
  box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
 }

 100% {
  box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
 }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
  transition: color 0.3s ease;

  &:hover {
    color: #008c8c;
  }

  @media ${media.mobile} {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = () => {
    navigate('/signup'); 
  };

  const handleButtonClick2 = () => {
    navigate('/login'); 
  };

  return (
    <Nav>
      <LogoLink to="/">
        <Logo />
      </LogoLink>
      <Hamburger onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </Hamburger>
      
      <AuthLinks isOpen={isOpen}>
        
        <Button onClick={handleButtonClick}>Schedule a Demo</Button>
        <Button2 onClick={handleButtonClick2}>Log in</Button2>
      </AuthLinks>
    </Nav>
  );
};

export default Navbar;
