import React, { useState } from 'react';
import styled from 'styled-components';
import Navbar from './NavigationLanding';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';


const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: white;
`;

const FormWrapper = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 5px;
  width: 400px;
  align-items: center;
`;

const FormTitle = styled.h2`
  color: black;
  text-align: center;
  margin-bottom: 30px;
  font-size: 3rem;
  font: 700 3rem Geist, sans-serif;
  padding-bottom: 80px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 30px;
  border: none;
  border-radius: 10px;
  background-color: white;
  border: 1px solid black;
  color: black;
  font: 400 1rem Geist, sans-serif;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  width: 120px;
  background-color: #174b42;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font: 500 1rem Geist, sans-serif;
`;

const Signup = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [businessName, setBusinessName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', { name, email, phone, businessName });
  
    try {
      await submit_form();
      navigate('/thankyou');
    } catch (error) {
      console.error('Submission failed:', error);
      // Optionally, display an error message to the user
    }
  };
  
  const submit_form = async () => {
    const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfgAB4MGcgaA6eateHQhpnB48TVmhX3pff0ohT2j_v05n4LSQ/formResponse';
    const formData = new URLSearchParams({
      'entry.241551853': name,
      'entry.729463263': email,
      'entry.578062305': phone,
      'entry.1579262465': businessName,
    });
  
    try {
      console.log('Submitting form with data:', formData.toString());
      const response = await fetch(formUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log('Form submitted successfully');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  
  

   

  return (
    <>
      <Navbar />
      <FormContainer>
        <FormWrapper>
          <FormTitle>Sign Up</FormTitle>
          <form onSubmit={handleSubmit}>
            <FormInput
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <FormInput
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <FormInput
              type="tel"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <FormInput
              type="text"
              placeholder="Business Name"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              required
            />
            <ButtonWrapper>
              <SubmitButton type="submit">Submit</SubmitButton>
            </ButtonWrapper>
          </form>
        </FormWrapper>
      </FormContainer>
      <Footer />
    </>
  );
};

export default Signup;