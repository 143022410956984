import React, { useState } from 'react';
import styled from 'styled-components';
import Navbar from './NavigationLanding';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: black;
`;

const FormWrapper = styled.div`
  background-color: black;
  padding: 40px;
  border-radius: 5px;
  width: 400px;
  align-items: center;
`;

const FormTitle = styled.h2`
  color: white;
  text-align: center;
  margin-bottom: 30px;
  font-size: 3rem;
  font: 700 3rem Geist, sans-serif;
  padding-bottom: 80px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 30px;
  border: none;
  border-radius: 10px;
  background-color: black;
  border: 1px solid white;
  color: white;
  font: 400 1rem Geist, sans-serif;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  width: 120px;
  background-color: #174b42;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font: 500 1rem Geist, sans-serif;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 20px;
`;

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [registerToken, setRegisterToken] = useState('')
  const [error, setError] = useState(''); // Add state for error message
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const success = await registerUser();
      if (success) {
        navigate('/search'); // Redirect to login page after successful registration
      } else {
        setError('Registration failed. Please try again.'); // Set error message
      }
    } catch (error) {
      console.error('Registration failed:', error);
      setError('An error occurred during registration. Please try again.'); // Set error message for other errors
    }
  };

  const registerUser = async () => {
    const registerUrl = 'http://127.0.0.1:5000/register'; // URL for the registration endpoint
    const registerData = {
      username: email,
      password: password,
    };

    try {
      const response = await axios.post(registerUrl, registerData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        return true; // User registered successfully
      } else {
        setError(response.data?.error || 'Registration failed. Please try again.');
        return false; // Registration failed
      }
    } catch (error) {
      console.error('Error registering user:', error);
      setError(
        error.response?.data?.error || 'An unexpected error occurred. Please try again.'
      );
      return false;
    }
  };

  return (
    <>
      <Navbar />
      <FormContainer>
        <FormWrapper>
          <FormTitle>Register</FormTitle>
          <form onSubmit={handleSubmit}>
            <FormInput
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <FormInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <FormInput
            type='text'
            value={registerToken}
            onChange={(e) => setRegisterToken(e.target.value)}
            required
            />
            <ButtonWrapper>
              <SubmitButton type="submit">Register</SubmitButton>
            </ButtonWrapper>
          </form>
          {error && <ErrorMessage>{error}</ErrorMessage>} {/* Display error message */}
        </FormWrapper>
      </FormContainer>
      <Footer />
    </>
  );
};

export default Register;
