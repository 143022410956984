import React from "react";
import Home from "./components/Home";
import SignUp from "./components/Signup";
import Pricing from "./components/Pricing";
import Aboutus from "./components/Aboutus";
import ThankYou from "./components/ThankYou";
import Login from "./components/Login";
import Search from "./components/Search";
import { Routes, Route } from 'react-router-dom';
import styled from "styled-components";
import Register from "./components/Register";

// Add this new component
const Wrapper = styled.div`
  overflow-x: hidden;
  width: auto;
`;
function App() {
  return (
    <Wrapper>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/signup" element={<SignUp/>}/>
                <Route exact path="/pricing" element={<Pricing/>}/>
                <Route exact path="/aboutus" element={<Aboutus/>}/>
                <Route exact path="/thankyou" element={<ThankYou/>} />
                <Route exact path="/login"  element={<Login/>} />
                <Route path="/search" element={<Search/>} />
                <Route path="/Register" element={<Register/>} />
            </Routes></Wrapper>
  );
}

export default App;
