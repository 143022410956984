import React from 'react';
import styled from 'styled-components';
import Navbar from './NavigationLanding';
import Footer from './Footer';

const Container = styled.div`
  height: auto;
  background: black;
  padding: 2rem;
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 100%;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #174b42;
  font-family: Inter, sans-serif;
  font-weight: 700;
  padding-top: 50px;
  @media (max-width: 768px) {
    font-size: 2.5rem;
    padding-top: 30px;
  }
`;

const MissionText = styled.p`
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  color: white;
  font-family: Inter, sans-serif;
  padding: 0 11rem;
  font-weight: 500;
  @media (max-width: 768px) {
    padding: 0 2rem;
  
  }
`;



const Aboutus = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Title>Mission</Title>
      <MissionText>
        At Lexiol, our goal is to bring big-box level analytics to all independent pharmacies. 
        We work to increase the efficiency of your inventory management in order to save
        you money.
      </MissionText>
      <MissionText>
        By providing analysis and reports about your inventory we aim to save you 
        hundreds of management hours every month.
      </MissionText>
      <Title>Our Team</Title>
        </Container>
        <Footer />
    </>
  );
};

export default Aboutus;